
import * as enviroment from '../../enviroment';
// import Logo from '../../asserts/images/logo.png';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../shared/appRoutes';
import { Logo } from '../logo/logo';

export const Footer = () => {
	return (
		<div className="footer_section layout_padding">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="location_text">
							<ul>
								<li>
									{/* <a href="https://www.google.com/maps/place/Calle+Adolfo+L%C3%B3pez+Mateos,+Despacho+3,+1er+Piso+n%C3%BAmero+60,+Colonia+La+Mora,+Ecatepec+de+Morelos,+C%C3%B3digo+Postal+55000,+Estado+de+M%C3%A9xico" target="_blank" rel="noreferrer" aria-hidden="true"> */}
									<a href="https://www.google.com/maps?q=19.502311,-99.158583" target="_blank">
										<i className="fa fa-map-marker" aria-hidden="true"></i>
									</a>
								</li>
								{	
									[
										enviroment[process.env.NODE_ENV].WHATSAPP_1, 
										enviroment[process.env.NODE_ENV].WHATSAPP_2, 
										enviroment[process.env.NODE_ENV].WHATSAPP_3,
									].map((number, index) => 
										<li key={index}>
											<a className="whatsappLink mobile" href={`whatsapp://send?text=${enviroment[process.env.NODE_ENV].WHATSAPP_TEXT}&phone=${number}&abid=${number}`} target="_blank" rel="noreferrer" aria-hidden="true">
												<i className="fa fa-phone" aria-hidden="true"></i>
											</a>
											<a className="whatsappLink desktop" href={`http://web.whatsapp.com/send?text=${enviroment[process.env.NODE_ENV].WHATSAPP_TEXT}&phone=${number}&abid=${number}`} target="_blank" rel="noreferrer" aria-hidden="true">
												<i className="fa fa-phone" aria-hidden="true"></i>
											</a>
										</li>
									) 
								}
								<li>
									<a href={`mailto:${enviroment[process.env.NODE_ENV].EMAIL_CORP}`} target="_blank" rel="noreferrer" aria-hidden="true"><i className="fa fa-envelope" ></i></a>
								</li>
							</ul>
						</div>
					</div>
					<div className="footer_logo">
						<Link to={AppRoutes.DEFAULT} className='pt-4'>
							<Logo />
						</Link>
						<div className="social_icon">
							<ul>
								<li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
								<li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
								<li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}