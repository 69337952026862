

import { useNavigate } from 'react-router-dom';
import { Item } from './item';
import './prices.css';
import { AppRoutes } from '../../shared/appRoutes';

export const Prices = ({ }) => {

	const navigate = useNavigate()

	const navegateToPriceList = () => navigate(AppRoutes.PRICE_LIST)

	return (
		<div className="price_section py-4">
			<div className="container">
				<div className="row">
					<div className="col-12">
						
							<div className='card-body'>

								<div className="row">
									<div className="col-sm-6 col-lg-6 " >
										<div className='corporate_text'>
											<h1 className='heading_text'>
												<b>Precios</b>
											</h1>
										</div>
										<div className='card-body'>
											<p>
												<b>PROTECTO BUILDING:</b> Protecciones Jurídicas para el Arrendamiento Inmobiliario ¡Hacemos su vida más tranquila y despreocupada con nuestros servicios de protección jurídica para el arrendamiento inmobiliario!
											</p>
											<p>
												Nuestros precios son las más competitivos del mercado.
											</p>
										</div>

										<div className="card-footer border-0 bg-white">
											<div className="readmore_button"
												onClick={() => navegateToPriceList()}
											>
												<button>Listado de precios</button>
											</div>
										</div>

									</div>
										<div className="col-sm-6 col-lg-6">
											<h1 className='corporate_text heading_text'>Documentos</h1>

											<div className='card-body'>
												<div className='text-center'>
													<a href="/docs/FORMATO_DESCARGABLE.docx" target='_self'>Formato Descargable</a>
												</div>
											</div>
										</div>
										
											
										
									{/* <div className="col-sm-6 col-lg-6">
										<div className='card border-0 our_work'>

											<Item title={'Consultoría y asesoría legal en materia de arrendamiento inmobiliario'}
												price={100}
												description={'La consultoría y asesoría legal en materia de arrendamiento inmobiliario es fundamental para garantizar un acuerdo justo y beneficioso para ambas partes involucradas.'}
											/>

											<Item title={'Redacción y revisión de contratos de arrendamiento'}
												price={100}
												description={'La redacción y revisión de contratos de arrendamiento es una tarea fundamental para garantizar la seguridad y protección de ambas partes involucradas en el acuerdo.'}
											/>

											<Item title={'Representación en procesos legales relacionados con el arrendamiento'}
												price={200}
												description={'La representación adecuada en procesos legales relacionados con el arrendamiento es fundamental para garantizar los derechos y deberes tanto del arrendador como del arrendatario.'}
											/>

											<Item title={'Gestión de cobros y reclamación de deudas por parte de arrendatarios'}
												price={200}
												description={'La gestión eficiente de cobros y reclamación de deudas por parte de los arrendatarios es fundamental para mantener una buena relación contractual.'}
											/>

										</div>
									</div> */}

								</div>

							</div>
						
					</div>
				</div>
			</div>
		</div>
	)
}