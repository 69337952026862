
import { Link } from 'react-router-dom';
import AngelOfIndependence from '../../asserts/images/ourWork/Angel-of-Independence.webp';

import './ourWork.css';
import { AppRoutes } from '../../shared/appRoutes';

export const OurWork = ({ onReadMore }) => {
	return (
		<>
			<div className="our_work_section">
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-md-6'>
							<div className='img-box'>
								<img className=' p-3' src={AngelOfIndependence} />
							</div>
						</div>
						<div className='col-xs-12 col-md-6 content  py-2'>
							<h1 className="mb-5 heading_text heading_text">
								<b>Nuestro Trabajo</b>
							</h1>
							<p>
								Los servicios de PROTECTO BUILDING son una excelente opción para aquellos propietarios que buscan proteger su patrimonio y su tranquilidad en el arrendamiento de sus inmuebles.
							</p>
							<p>
								Con su amplia gama de protecciones jurídicas, esta empresa se encarga de brindar asesoría a sus clientes y de velar por sus intereses en caso de cualquier situación legal relacionada con el arrendamiento.
							</p>
							<p>
								<Link to={AppRoutes.OUR_WORK} className="btn btn-link"><b>Ver más ...</b></Link>
							</p>
						</div>
					</div>
				</div>
			</div>

{/* 
			<div className="our_work_section py-4">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className='card shadow-sm'>
								<div className='card-body'>

									<div className="row">
										<div className="col-sm-6 col-lg-6 img_container" >
											<img className='w-100 p-3' src={AngelOfIndependence} />
										</div>
										<div className="col-sm-6 col-lg-6">
											<div className='card border-0 our_work'>
												<div className='corporate_text'>Nuestro Trabajo</div>
												<div className='card-body'>
													<p>
														Los servicios de PROTECTO BUILDING son una excelente opción para aquellos propietarios que buscan proteger su patrimonio y su tranquilidad en el arrendamiento de sus inmuebles. Con su amplia gama de protecciones jurídicas, esta empresa se encarga de brindar asesoría a sus clientes y de velar por sus intereses en caso de cualquier situación legal relacionada con el arrendamiento.
													</p>
												</div>
												<div className="card-footer border-0 bg-white">
													<div className="readmore_button" 
														onClick={() => onReadMore()} 
													>
														<button>Leer mas</button>
													</div>
												</div>
											</div>
										</div>
										
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			 */}
		</>
	)
}