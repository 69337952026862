
export default function Item1({
    image,
    imageActive,
    title,
    description,
    onBooking
}) {
    return (
        <div className="card border-0 service_box" style={{ minWidth: '350px', padding: '1.2rem' }}>
            <div className="house_icon">
                <img src={image} className="image_1 rounded" alt={title}/>
                {/* <img src={imageActive} className="image_2" alt={title}/> */}
            </div>
            <div className="card-body">
                <h3 className="corporate_text">{title}</h3>
                <p className="chunks_text">{description}</p>
            </div>
            <div className="card-footer border-0 bg-white">
                <div className="readmore_button" 
                    onClick={() => onBooking()} 
                    data-bs-toggle="modal" 
                    data-bs-target="#staticBackdrop"
                    >
                    <button>Contactenos</button>
                </div>
            </div>
        </div>
    );
}