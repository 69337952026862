
import OutWork from '../../asserts/images/ourWork/Angel-of-Independence.webp';


export const OurWork = ({ }) => {
	return (
		<div className="our_work_section py-4">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className='card- shadow-sm'>
							<div className='card-body'>

								<div className="row">
									<div className="col-sm-6 col-lg-6">
										<div>
											<img className='w-100 p-3' src={OutWork} />
										</div>
										<div>
											<p>
												Estimados clientes, hoy en día es cada vez más común que las personas opten por el arrendamiento inmobiliario en lugar de la compra de una propiedad. Esta opción ofrece flexibilidad y comodidad, pero también puede conllevar ciertos riesgos para los propietarios. En este sentido, es crucial contar con una protección jurídica adecuada que brinde seguridad y tranquilidad a los arrendadores.
											</p>
										</div>
									</div>
									<div className="col-sm-6 col-lg-6">
										<div className='card border-0 our_work'>
											<h1 className='corporate_text heading_text'>Nuestro Trabajo</h1>
											<div className='card-body'>
												<p>
													Los servicios de PROTECTO BUILDING son una excelente opción para aquellos propietarios que buscan proteger su patrimonio y su tranquilidad en el arrendamiento de sus inmuebles. Con su amplia gama de protecciones jurídicas, esta empresa se encarga de brindar asesoría a sus clientes y de velar por sus intereses en caso de cualquier situación legal relacionada con el arrendamiento.
												</p>
												<p>
													En el mundo actual, el arrendamiento inmobiliario se ha convertido en una opción cada vez más común para aquellos que desean tener un lugar donde vivir o establecer su negocio. Sin embargo, este tipo de contrato puede llegar a ser complejo y requerir de una atención constante por parte de ambas partes involucradas.
												</p>
											</div>

											<h1 className='corporate_text heading_text'>Misión</h1>
											<div className='card-body'>
												<p>
													Nuestro fin primordial es darle la confianza, a usted como arrendador (o asesor inmobiliario) para que pueda rentar su inmueble sin temor. Si usted está decidido a rentar, proteja su patrimonio con la confianza de una protección jurídica de PROTECTO BUILDING y tenga la certeza de que su inmueble estará respaldado por nuestro equipo de profesionales.
												</p>
												<p>
													Es necesario estar preparado ante la variedad de imprevistos y eventualidades que pueden presentarse en el entorno inmobiliario, proteja su inmueble con nuestro grupo de expertos.
												</p>
												<p>
													Nuestros abogados le acompañan durante todo el proceso del arrendamiento, elaboración de contrato y firmas.
												</p>
											</div>

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}