import { Outlet } from "react-router-dom";

import NavBar from "../components/navbar/navbar";
import { Footer } from "../components/footer/footer";
import { CopyRight } from "../components/copyright/copyright";

export const Layout = () => {
	return (

		<div className="layout">
			<div className="header_section" >
				<NavBar />
			</div>
			<div >
				<Outlet />
			</div>
			<div>
				<Footer />
				<CopyRight />
			</div>
		</div>
	);
}


