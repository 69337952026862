import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "font-awesome/css/font-awesome.css";

import { Home } from './pages/home/home';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { AppRoutes } from './shared/appRoutes';

import { Layout } from './pages/layout';
import { OurWork } from './pages/ourWork/ourWork';

import { ServicesPage } from './pages/services1/services'
import { AboutUs } from './pages/aboutUs/aboutUs';
import { ContactUs } from './pages/contactUs/contactUs';
import { HowWeWork } from './pages/howWeWork/howWeWork';
import { PriceList } from './pages/priceList/priceList';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: AppRoutes.HOME,
        element: <Home />,
      },
      {
        path: AppRoutes.OUR_WORK,
        element: <OurWork />,
      },
      {
        path: AppRoutes.SERVICES,
        element: <ServicesPage />,
      },
      {
        path: AppRoutes.PRICE_LIST,
        element: <PriceList />,
      },

      
      {
        path: AppRoutes.ABOUT_US,
        element: <AboutUs />,
      },
      {
        path: AppRoutes.CONTACT_US,
        element: <ContactUs />
      },
      {
        path: AppRoutes.HOW_WE_WORK,
        element: <HowWeWork />
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
