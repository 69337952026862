

export const CopyRight = () => {
	return (
		<div className="copyright_section">
			<div className="container">
				<p className="copyright_text">2024 All Rights Reserved.</p>
			</div>
		</div>
	);
}