import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//import Logo from '../../asserts/images/logo.png'
//import ShielLock from '../../asserts/images/shield-lock.svg'

import * as enviroment from '../../enviroment';

import { AppRoutes } from '../../shared/appRoutes';
import { Logo } from '../logo/logo';

export default function NavBar() {
  const location = useLocation();

  const buttonCloseRef = useRef(null);

  const doAction = () => {
    buttonCloseRef.current.click();
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: '#001431'}}>
      <div className="container">
        <Link className="navbar-brand" to={AppRoutes.DEFAULT}>
          <Logo />
        </Link>
        <button className="navbar-toggler" 
          type="button" 
          data-bs-toggle="offcanvas" 
          data-bs-target="#offcanvasNavbar" 
          aria-controls="offcanvasNavbar" 
          aria-label="Toggle navigation"
          style={{ borderColor: 'white', backgroundColor: 'white'}}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">{enviroment[process.env.NODE_ENV].NAME_CORP}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ref={buttonCloseRef}></button>
          </div>
          <div className="offcanvas-body" >
            <ul className="navbar-nav mr-auto">
              <li className={`nav-item ${location.pathname === AppRoutes.HOME ? 'active' : ''}`}>
                <Link className="nav-link" to={AppRoutes.HOME} onClick={() => doAction()}>Inicio</Link>
              </li>
              {/* 
              <li className={`nav-item ${location.pathname === AppRoutes.ABOUT_US ? 'active' : ''}`}>
                <Link className="nav-link" to={AppRoutes.ABOUT_US} onClick={() => doAction()}>Quienes somos</Link>
              </li> 
              */}
              <li className={`nav-item ${location.pathname === AppRoutes.OUR_WORK ? 'active' : ''}`}>
                <Link className="nav-link" to={AppRoutes.OUR_WORK} onClick={() => doAction()}>Que hacemos</Link>
              </li>
              <li className={`nav-item ${location.pathname === AppRoutes.SERVICES ? 'active' : ''}`}>
                <Link className="nav-link" to={AppRoutes.SERVICES} onClick={() => doAction()}>Servicios</Link>
              </li>
              <li className={`nav-item ${location.pathname === AppRoutes.CONTACT_US ? 'active' : ''}`}>
                <Link className="nav-link" to={AppRoutes.CONTACT_US} onClick={() => doAction()}>Contactenos</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}