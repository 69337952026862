export const development = {
    NAME_CORP: 'Protector Building',
    EMAIL_CORP: 'masterlegalprotected@gmail.com',
    WHATSAPP_TEXT: '[Escriba su mensaje]...',
    WHATSAPP_1: '+525538606249',
    WHATSAPP_2: '+525568600925',
    WHATSAPP_3: '+525654664669',
    EMAILJS_SERVICE_ID: 'service_MLP_Gmail',
    EMAILJS_PUBLIC_KEY: 'TghbWt53PNrUldo6U',

    RECAPTCHA_SITE_KEY: '6LfYSb4pAAAAAPZy1IV7HzyubFXaUtc-neuEXwlt'
};
  
export const production = {
    NAME_CORP: 'Protector Building',
    EMAIL_CORP: 'masterlegalprotected@gmail.com',
    WHATSAPP_TEXT: '[Escriba su mensaje]...',
    WHATSAPP_1: '+525538606249',
    WHATSAPP_2: '+525568600925',
    WHATSAPP_3: '+525654664669',
    EMAILJS_SERVICE_ID: 'service_MLP_Gmail',
    EMAILJS_PUBLIC_KEY: 'TghbWt53PNrUldo6U',
    
    RECAPTCHA_SITE_KEY: '6LfYSb4pAAAAAPZy1IV7HzyubFXaUtc-neuEXwlt'
};
  
export const test = {
    NAME_CORP: 'Protector Building',
    EMAIL_CORP: 'masterlegalprotected@gmail.com',
    WHATSAPP_TEXT: '[Escriba su mensaje]...',
    WHATSAPP_1: '+525538606249',
    WHATSAPP_2: '+525568600925',
    WHATSAPP_3: '+525654664669',
    EMAILJS_SERVICE_ID: 'service_MLP_Gmail',
    EMAILJS_PUBLIC_KEY: 'TghbWt53PNrUldo6U',
    
    RECAPTCHA_SITE_KEY: '6LfYSb4pAAAAAPZy1IV7HzyubFXaUtc-neuEXwlt'
};

export default development;
