
import AboutUsPng from '../../asserts/images/aboutUs/aboutUs.png';

import './aboutUs.css';

export const AboutUs = () => {
	return (
		<div className="about_section py-4">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className='card- shadow-sm'>
							<div className='card-body'>

								<div className="row">
									<div className="col-sm-6 col-lg-6 " >
										<div className='img_container'>
											<img className=' p-3' src={AboutUsPng} width={382} />
										</div>
										<div className='card-body'>
											<p><b>SOMOS UN BUFFETE JURÍDICO</b>, especializados en la Protección Jurídica de Arrendamiento</p>
											<p>
												¿HAS TENIDO UN INQUILINO PROBLEMÁTICO?<br/>
												¿TU INQUILINO NO TE PAGA LA RENTA?
											</p>
											<p>
												Te ofrecemos Protección Jurídica!
											</p>
											<p>
												<b>LA PROTECCIÓN JURÍDICA DE ARRENDAMIENTO</b> es un contrato de prestación de servicios profesionales, suscrito entre el propietario y nosotros.
											</p>
										</div>
									</div>
									<div className="col-sm-6 col-lg-6">
										<div className='card border-0 our_work'>
											<div className='corporate_text text-center'>
												<h1 className="mb-5 heading_text"><b>Sobre Nosotros</b></h1>
											</div>
											<div className='card-body text-align-left'>
												<p>
													<b>La protección jurídica</b> para el arrendamiento inmobiliario asegura los derechos de arrendadores y arrendatarios, es un instrumento jurídico que le permite proteger su inmueble y su patrimonio.
													Con la compra de su Protección Jurídica le incluimos; Elaboración de su contrato de arrendamiento confeccionado con el clausulado más eficaz que en derecho proceda, protegiéndolo de cualquier mala actuación de su inquilino, representación de nuestro despacho ante sus inquilinos, fiadores y obligados solidarios, también realizamos amplia investigación legal y civil del inquilino y su fiador garantizando que es un buen prospecto, acudimos a la firma del contrato brindando la siempre conveniente imagen ante sus inquilinos de que usted está siempre respaldado por un equipo de Abogados Profesionales,  Hacemos la cobranza extrajudicial y judicial de rentas y servicios adeudados por el inquilino, tales como son agua, gas, luz y mantenimiento, además gestionamos la entrega y desocupación del inmueble por el incumplimiento del inquilino de acuerdo al contrato de arrendamiento
												</p>
											</div>

											<div className='corporate_text'>
												<h1 className="mb-5 heading_text"><b>Misión</b></h1>
											</div>
											<div className='card-body text-align-left'>
												<p>
													Nuestro fin primordial es darle la confianza, a usted como arrendador (o asesor inmobiliario) para que pueda rentar su inmueble sin temor. Si usted está decidido a rentar, proteja su patrimonio con la confianza de una protección jurídica y tenga la certeza de que su inmueble estará respaldado por nuestro equipo de profesionales
													Es bueno estar preparado ante la variedad de imprevistos y eventualidades que pueden presentarse en el entorno inmobiliario, proteja su inmueble con nuestro grupo de expertos.
													Nuestros abogados le acompañan durante todo el proceso del arrendamiento, elaboración de contrato y firmas.
												</p>
											</div>

										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
