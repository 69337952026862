
export const AppRoutes = {

    DEFAULT: '/',
    HOME: '/home',

    OUR_WORK: '/out_work',
    PRICE_LIST: '/price_list',



    ABOUT_US: '/about_us',
    SERVICES: '/services',
    CONTACT_US: '/contact_us',
    HOW_WE_WORK: '/how_we_work'
}