import * as enviroment from '../../enviroment';

export const Logo = () => {
	return (
		<div>
			<i className='fa fa-balance-scale fa-5' style={{ color: '#630708', fontSize: '40px' }}></i>
			<span style={{
				color: 'rgb(255, 212, 27)',
				verticalAlign: 'super',
				marginLeft: '10px',
				fontWeight: '800'
			}}
			>{enviroment[process.env.NODE_ENV].NAME_CORP}</span>
		</div>
	)
}