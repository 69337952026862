import { Routes, useNavigate } from "react-router-dom";
import { AboutUs } from "../../components/aboutUs/aboutUs";
import { Banner } from "../../components/banner/banner";
import Box from "../../components/box/box";
import { ContactUs } from "../../components/contactUs/contactUs";
import { OurWork } from "../../components/ourWork/ourWork";
import { Services1 } from "../../components/services/services1";
import { AppRoutes } from "../../shared/appRoutes";
import { Prices } from "../../components/prices/prices";

export const Home = () => {

  const navigate = useNavigate()

  return (
    <>
      {/* <Banner /> */}

      <AboutUs />
      
      <div className="extra_section">
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-md-12'>
              <div className='img-box'>
              </div>
            </div>
            <div className='col-xs-12 col-md-12 content py-2'>
              <h1 className='corporate_text heading_text'>Ofrecemos Protección Jurídica</h1>
              <div className='card-body'>
                <p>
                  <b>La protección jurídica</b> para el arrendamiento inmobiliario asegura los derechos de arrendadores y arrendatarios a través de contratos bien elaborados. Una Protección Jurídica cubre aspectos como pago de renta, mantenimiento, pago de servicios. Es un instrumento jurídico que le permite proteger su inmueble y su patrimonio, junto con la compra de su Protección Jurídica le brindamos asesoría y los servicios más amplios como, Elaboración de su contrato de arrendamiento confeccionado con el clausulado más eficaz que en derecho proceda, protegiéndolo de cualquier mala actuación de su inquilino, representación de nuestro despacho ante sus inquilinos, fiadores y obligados solidarios.
                </p>
                <p>
                  Realizamos amplia investigación legal y civil del inquilino y su fiador; acudimos a la firma del contrato brindando la siempre conveniente imagen ante sus inquilinos de que usted está siempre respaldado por un equipo de Abogados Profesionales. Hacemos la cobranza extrajudicial y judicial de rentas y servicios adeudados por el inquilino, según sea el caso, tales como son agua, gas, luz y mantenimiento, además gestionamos la entrega y desocupación del inmueble por el incumplimiento del inquilino de acuerdo al contrato de arrendamiento.
                </p>
                <p>
                  Contamos con tres tipos de protección jurídica de arrendamiento que te permitirán:
                </p>
                <p><b>1- Proteger el cobro de tus rentas, de los servicios, de los daños.</b></p>
                <p><b>2- Desocupación y entrega de tu inmueble.</b></p>
                <p><b>3- Protegerte contra la ley de Extinción de Dominio.</b></p>
                <p>Tipos de protección:</p>
                <p>Protección <b>Esencial</b><br/>
                  Protección Esencial gestionamos la entrega y desocupación del inmueble hacemos cobranza extrajudicial y notificaciones.
                </p>
                <p>Protección <b>Múltiple</b><br/>
                  Protección múltiple gestionamos entrega y desocupación del inmueble, pago de servicios, pago de rentas adeudadas.
                </p>
                <p>Protección <b>Integral</b><br/>
                  Protección integral gestionamos entrega y desocupación del inmueble recuperación de servicios vencidos, recuperación de rentas no pagadas, recuperación por pago de daños, intervención de abogados para promover la recuperación del inmueble, 30 días más de protección jurídica.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Prices></Prices>

      <Services1 small={true} />

      <div className="extra_section">
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-md-12'>
              <div className='img-box'>
              </div>
            </div>
            <div className='col-xs-12 col-md-12 content py-2'>
              <h1 className="mb-5 heading_text text-center">
                <b>Además contamos con</b>
              </h1>
              <p>
                <b>Asesoría legal en materia de arrendamiento inmobiliario.</b><br/>
                Es importante contar con una asesoría legal en materia de arrendamiento inmobiliario para garantizar que todos los contratos y acuerdos sean justos y estar debidamente protegidos.
              </p>
              <p>
                <b>Representación y defensa en caso de litigios de arrendamiento.</b><br/>
                Es importante contar con una representación legal experta y comprometida en caso de litigios de arrendamiento, para defender nuestros derechos como arrendatarios y garantizar un proceso EXITOSO.
              </p>
              <p>
                <b>Asesoría en materia de impuestos relacionados con el arrendamiento inmobiliario.</b><br/>
                Es importante contar con una buena asesoría en materia fiscal, e impuestos para el arrendamiento, ya que nos permitirá cumplir adecuadamente con nuestras obligaciones fiscales.
              </p>
              <p>
                <b>Formación y capacitación</b><br/>
                La formación y capacitación son una parte esencial para el desarrollo de los empleados, Asesores, ya que ayudan a mejorar la elaboración de todo tipo de contratos de compra-venta, de participación, laborales, de franquicias, asociación.
              </p>    
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <p>
                    <b>Administración Inmobiliaria</b>
                    <li>- Asesoría legal en materia de arrendamiento inmobiliario.</li>	
                    <li>- Representación y defensa en caso de litigios de arrendamiento.</li>
                    <li>- Gestión de cobranza de rentas y gestión de desahucio en caso de incumplimiento.</li>
                    <li>- Asesoría en materia de impuestos relacionados con el arrendamiento inmobiliario.</li>
                    <li>- Formación y capacitación para asesores inmobiliarios en materia de arrendamiento.</li>
                  </p>
                </div>
                <div className="col-xs-12 col-md-6">
                  <p>
                    <b>Estamos especializados en las siguientes disciplinas del Derecho</b>
                    <li>- Protección Jurídica de Arrendamiento.</li> 
                    <li>- Derecho y asesoría fiscal.</li>
                    <li>- Migración.</li> 
                    <li>- Derecho Mercantil.</li> 
                    <li>- Derecho laboral.</li> 
                    <li>- Derecho Civil.</li> 
                    <li>- Marcas y derechos de autor.</li>  
                    <li>- Gestoría en materia de Establecimientos Mercantiles, Protección Civil, Seduvi, RPPC.</li>
                    <li>- Licencias de Construcción, Regularización de Inmuebles.</li>
                  </p>
                </div>
              </div>
         
            </div>
          </div>
        </div>
      </div>
      
      <OurWork onReadMore={() => navigate(AppRoutes.OUR_WORK)}></OurWork>
      
      <ContactUs />

      {/* 
        <Services></Services> 
        */}

      {/* 
        <Box />
        <section className="pt-3">
          <Services />
        </section>
        <AboutUs />
        <ContactUs /> 
        */}

      {/*         
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="10000">
              <span className="d-block w-100" alt="...">PRIMERO</span>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <span className="d-block w-100" alt="...">SEGUNDO</span>
            </div>
            <div className="carousel-item">
              <span className="d-block w-100" alt="...">TERCERA</span>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div> 
        */}

    </>
  );
}