

import { useNavigate } from 'react-router-dom';
import { Item } from './item';
import './prices.css';
import { AppRoutes } from '../../shared/appRoutes';

export const PriceList = ({ }) => {

	return (
		<div className="our_work_section py-4">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className='card- shadow-sm-'>
							<div className='card-body'>

								<div className="row">
									<div className="col-sm-12 col-lg-12" >
										<h1 className='corporate_text heading_text'>Lista de Precios</h1>
										<div className='card-body mt-2'>

											<table className='w-100 text-center'>
												<thead>
													<tr>
														<th width={'25%'}>IMPORTE MENSUAL DE RENTA</th>
														<th width={'25%'}>PROTECCIÓN JURÍDICA ESENCIAL</th>
														<th width={'25%'}>PROTECCIÓN JURÍDICA MULTIPLEX</th>
														<th width={'25%'}>PROTECCIÓN JURÍDICA INTEGRAL</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>$3,001 a $5,000</td><td>$2,800</td><td>$3,850</td><td>$5,000</td>
													</tr>
													<tr>
														<td>$5,001 A $7,000</td><td>3,000</td><td>4,050</td><td>5,000</td>
													</tr>
													<tr>
														<td>$7,001 a $10,000</td><td>3,300</td><td>4,500</td><td>5,000</td>
													</tr>
													<tr>
														<td>$10,001 a $15,000</td><td>3,650</td><td>5,600</td><td>55% de la renta</td>
													</tr>
													<tr>
														<td>$15,001 a $20,000</td><td>4,200</td><td>5,800</td><td>55% de la renta</td>
													</tr>
													<tr>
														<td>$20,001 a $25,000</td><td>5,250</td><td>8,500</td><td>55% de la renta</td>
													</tr>
													<tr>
														<td>$25,001 a $30,000</td><td>6,300</td><td>10,200</td><td>55% de la renta</td>
													</tr>
													<tr>
														<td>$30,000 a $35,000</td><td>7,350</td><td>11,900</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$35,000 a $40,000</td><td>8,400</td><td>13,600</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$40,001 a $45,000</td><td>9,450</td><td>15,300</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$45,000 a $50,000</td><td>10,500</td><td>17,000</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$50,001 a $55,000</td><td>11,550</td><td>18,700</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$55,001 a $60,000</td><td>12,600</td><td>20,400</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$60,001 a $65,000</td><td>13,650</td><td>22,100</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$65,001 a $70,000</td><td>14,700</td><td>23,800</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$70,001 a $75,000</td><td>15,750</td><td>25,500</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$75,001 a $80,000</td><td>16,800</td><td>27,200</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$80,001 a $85,000</td><td>17,850</td><td>28,900</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$85,001 a $90,000</td><td>19,800</td><td>30,600</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$90,001 a $95,000</td><td>19,950</td><td>32,300</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$95,001 a $100,000</td><td>21,000</td><td>34,000</td><td>60% de la renta</td>
													</tr>
													<tr>
														<td>$100,001 a $105,000</td><td>22,050</td><td>35,700</td><td>60% de la renta</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}