export const HowWeWork = () => {
    return (
        <div>
            <div>
                <h2 className="does_taital">Proveemos practica legal</h2>
                <div className="dummy_text" style={{ textAlign: 'justify'}}>
                    <p>
                        En nuestro prestigioso bufete de abogados, nos destacamos por ofrecer servicios legales excepcionales y de alta calidad. Nuestra vasta experiencia y profundo conocimiento del derecho nos permiten abordar una amplia gama de casos con éxito. Valoramos la integridad en cada paso del proceso legal, asegurando que nuestros clientes confíen plenamente en nosotros para representar sus intereses con honestidad y transparencia.
                    </p>
                    <p>
                        Nos enorgullece trabajar estrechamente con cada cliente, entendiendo sus necesidades y objetivos individuales. Creemos firmemente en la importancia de soluciones personalizadas, ya que reconocemos que cada situación legal es única. Por lo tanto, nos esforzamos por desarrollar estrategias adaptadas a las circunstancias específicas de cada caso, garantizando así resultados óptimos para nuestros clientes.
                    </p>
                    <p>
                        En resumen, en nuestro bufete de abogados nos comprometemos a proporcionar un servicio excepcional que se distingue por nuestra experiencia, integridad y enfoque personalizado. Estamos aquí para guiar y representar a nuestros clientes con dedicación y profesionalismo en cada paso del camino legal.
                    </p>
                </div>
            </div>

            <div>
                <h2 className="does_taital">Garantizamos confidencialidad</h2>
                <div className="dummy_text" style={{ textAlign: 'justify'}}>
                    <p>
                        En nuestro bufete de abogados, nos comprometemos firmemente a salvaguardar la confidencialidad de cada uno de nuestros clientes. Reconocemos que la confianza es fundamental en cualquier relación entre abogado y cliente, y por ello, nos esforzamos por crear un entorno seguro y confidencial en el que puedan compartir sus preocupaciones legales sin reservas.
                    </p>
                    <p>
                        Nuestro equipo de profesionales legales está altamente capacitado en la protección de la información confidencial. Desde el momento en que nuestros clientes nos confían sus casos, implementamos estrictas medidas de seguridad para garantizar que su información se maneje con el más alto grado de confidencialidad y privacidad.
                    </p>
                    <p>
                        Entendemos que cada situación legal puede ser delicada y sensible, por lo que tratamos cada caso con el máximo respeto y discreción. Nuestros clientes pueden tener la tranquilidad de que cualquier información que compartan con nosotros se mantendrá en estricta confidencialidad, protegiendo así su privacidad y sus intereses legales.
                    </p>
                    <p>
                        En resumen, en nuestro bufete de abogados, la garantía de confidencialidad es un pilar fundamental de nuestro compromiso con la excelencia en el servicio. Estamos dedicados a proteger la privacidad de nuestros clientes en todo momento y en todas las circunstancias.
                    </p>
                </div>
            </div>

            <div >
                <h2 className="does_taital" name="Promptness_In_Management">Prontitud en la gestion</h2>
                <div className="dummy_text" style={{ textAlign: 'justify'}}>
                    <p>
                        En nuestro respetado bufete de abogados, nos destacamos por nuestra capacidad para gestionar los asuntos legales de nuestros clientes de manera rápida y eficiente. Reconocemos que la prontitud en la gestión de los casos es esencial para nuestros clientes, quienes a menudo enfrentan situaciones urgentes que requieren atención inmediata.
                    </p>
                    <p>
                        Nuestro equipo de abogados altamente capacitados está comprometido con la acción diligente y oportuna en cada etapa del proceso legal. Desde el momento en que un cliente nos confía su caso, trabajamos sin descanso para identificar y ejecutar las acciones necesarias para avanzar hacia una solución favorable.
                    </p>
                    <p>
                        Valoramos la importancia de mantener a nuestros clientes informados y actualizados sobre el progreso de sus casos. Por lo tanto, nos comprometemos a comunicarnos de manera clara y oportuna, brindando orientación experta y respuestas rápidas a cualquier pregunta o inquietud que puedan surgir.
                    </p>
                    <p>
                        Entendemos que el tiempo es un recurso valioso en el mundo legal, por lo que nos esforzamos por minimizar los retrasos y maximizar la eficiencia en todas nuestras operaciones. Nuestro compromiso con la prontitud en la gestión se refleja en nuestro enfoque proactivo y enérgico para resolver los problemas legales de nuestros clientes de manera rápida y efectiva.
                    </p>
                    <p>
                        En resumen, en nuestro bufete de abogados, la prontitud en la gestión es un valor fundamental que impulsa nuestra dedicación a proporcionar un servicio excepcional. Estamos comprometidos a actuar con rapidez y eficiencia para cumplir con las necesidades y expectativas de nuestros clientes en todo momento
                    </p>
                </div>
            </div>
        </div>
    )
}