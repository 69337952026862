import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

import ReCAPTCHA from "react-google-recaptcha";

import { emailTemplates } from '../../shared/emailTemplates';
import * as enviroment from '../../enviroment';
import { Alerts } from "../../shared/alerts";

export const ContactUs = ({ onClick }) => {

	const [alert, setAlert] = useState(Alerts.Empty)
	const [captcha, setCaptcha] = useState(false);

	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm(enviroment[process.env.NODE_ENV].EMAILJS_SERVICE_ID, emailTemplates.Contact_Us, form.current, {
			publicKey: enviroment[process.env.NODE_ENV].EMAILJS_PUBLIC_KEY,
		})
			.then(
				() => {
					console.log('SUCCESS!');
					setAlert(Alerts.Success);
				},
				(error) => {
					console.log('FAILED...', error.text);
					setAlert(Alerts.Error);
				},
			);
	};

	const onChange = (value) => {
		console.log("Captcha value:", value);
		setCaptcha(value);
	}

	return (
		<div className="contact_section py-4">
			<div className="container">

				<div className="row">
					<div className="col-12">

							<div className='card-body'>

								{alert === Alerts.Success &&
									<div className="alert alert-success alert-dismissible fade show" role="alert">
										Se envió el correo de contacto satisfactoriamente
										<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setAlert(Alerts.Empty)}></button>
									</div>
								}
								{alert === Alerts.Error &&
									<div className="alert alert-error alert-dismissible fade show" role="alert">
										No se pudo enviar correo de contacto
										<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setAlert(Alerts.Empty)}></button>
									</div>
								}

								<div className="row">
									<div className="col-sm-12">
										<div className="contact_taital">
											<h1 className="heading_text">
												<b>Contactenos</b>
											</h1>
										</div>
									</div>
								</div>
								<div className="contact_section_2">
									<div className="row">
										<div className="col-md-12">
											<div className="mail_section map_form_container">
												<form ref={form} onSubmit={sendEmail}>
													<input type="text" className="mail_text" placeholder="Nombre" name="user_name" required />
													<input type="tel" className="mail_text" placeholder="Número de Teléfono" name="user_phone" required />
													<input type="email" className="mail_text" placeholder="Correo Electrónico" name="user_email" required />
													<textarea className="massage-bt" placeholder="Mensaje" rows="5" id="message" name="message" required ></textarea>
													<button type="submit" className="send_bt active btn btn-primary" disabled={!captcha}>Enviar Ahora</button>
												</form>

												<div className="my-3">
													<ReCAPTCHA
														sitekey={enviroment[process.env.NODE_ENV].RECAPTCHA_SITE_KEY}
														onChange={onChange}
													/>
												</div>

											</div>
										</div>
									</div>
								</div>

							</div>

					</div>
				</div>
			</div>
		</div>
	)
}