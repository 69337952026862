import Item1 from "./item1"

import emailjs from '@emailjs/browser';

import ReCAPTCHA from "react-google-recaptcha";

import * as enviroment from '../../enviroment';
import { emailTemplates } from '../../shared/emailTemplates';


import LegalAdviceOnRealStateLeasing from '../../asserts/images/services/Legal-advice-on-real-state-leasing.png';
import ReviewAndDraftingOfLeaseContracts from '../../asserts/images/services/Review-and-drafting-of-lease-contracts.png';
import RepresentationAndDefenseInCaseOfRentalDisputes from '../../asserts/images/services/Representacion-y-defensa-en-caso-de-litigios-de-arrendamiento-1.png';
import RentCollectionManagementAndEvictionManagementInCaseOfNonCompliance from '../../asserts/images/services/Rent-collection-management-and-eviction-management-in-case-of-non-compliance.png';
import AdviceOnTaxesAndFeesRelatedToLeasing from '../../asserts/images/services/Advice-on-taxes-and-fees-related-to-leasing.png';
import TrainingAndQualification from '../../asserts/images/services/Training-and-qualification.png';

import { Link } from "react-router-dom";
import { AppRoutes } from "../../shared/appRoutes";
import { useEffect, useRef, useState } from "react";

import { Alerts } from "../../shared/alerts";


export const Services1 = ({ small }) => {

	const ProtectionTypes = { Essential: 'Esencial', Multiple: 'Múltiple', Comprehensive: 'Integral' }
	const [protectionType, setProtectionType] = useState('');
	const [alert, setAlert] = useState(Alerts.Empty);
	const [captcha, setCaptcha] = useState(false);

	const form = useRef();
	const buttonCloseModalRef = useRef();

	useEffect(() => {
		if (protectionType === '') setAlert(Alerts.Empty);
	}, [protectionType])

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm(enviroment[process.env.NODE_ENV].EMAILJS_SERVICE_ID, emailTemplates.Service, form.current, {
			publicKey: enviroment[process.env.NODE_ENV].EMAILJS_PUBLIC_KEY,
		})
			.then(
				() => {
					console.log('SUCCESS!');
					buttonCloseModalRef.current.click();
				},
				(error) => {
					console.log('FAILED...', error);
					console.log('FAILED...', error.text);
					setAlert(Alerts.Error);
				},
			);
	};

	const onChange = (value) => {
		console.log("Captcha value:", value);
		setCaptcha(value);
	}
	
	return (
		<div className="about_section py-4">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className='card- shadow-sm-'>
							<div className='card-body'>
								<div className="about_text_main">
									<h1 className="about_taital heading_text">Servicios</h1>
									{/* 
										<p className="fw-light fs-5"><b>{enviroment[process.env.NODE_ENV].NAME_CORP}:</b> Garantizando la tranquilidad en el arrendamiento inmobiliario El mercado inmobiliario es un sector en constante crecimiento, con una gran demanda de alquileres y arrendamientos.</p>
										<p className="fw-light fs-5">Sin embargo, también es un mercado que puede presentar ciertas complicaciones y riesgos para los propietarios de inmuebles.</p> 
									*/}
									<div className="row">
										<div className="d-flex flex-wrap justify-content-center card-group">
											<Item1
												image={LegalAdviceOnRealStateLeasing}
												imageActive={LegalAdviceOnRealStateLeasing}
												title='Asesoría legal en materia de arrendamiento inmobiliario'
												description='Es importante contar con una asesoría legal en materia de arrendamiento inmobiliario para garantizar que todos los contratos y acuerdos sean justos y estar protegidos.'
												onBooking={() => setProtectionType('Asesoría legal') }
											/>
											<Item1
												image={ReviewAndDraftingOfLeaseContracts}
												imageActive={ReviewAndDraftingOfLeaseContracts}
												title='Revisión y redacción de contratos de arrendamiento'
												description='Es necesario asegurarse de que la revisión y redacción de contratos de arrendamiento estén bien elaborados y claros, para evitar posibles conflictos entre ambas.'
												onBooking={() => setProtectionType('Revisión y redacción de contratos')}
											/>
											<Item1
												image={RepresentationAndDefenseInCaseOfRentalDisputes}
												imageActive={RepresentationAndDefenseInCaseOfRentalDisputes}
												title='Representación y defensa en caso de litigios de arrendamiento'
												description='Es importante contar con una representación legal experta y comprometida en caso de litigios de arrendamiento, para defender nuestros derechos como arrendatarios y garantizar un proceso.'
												onBooking={() => setProtectionType('Representación y defensa')}
											/>
											{small === false &&
												<>
													<Item1
														image={RentCollectionManagementAndEvictionManagementInCaseOfNonCompliance}
														imageActive={RentCollectionManagementAndEvictionManagementInCaseOfNonCompliance}
														title='Gestión de cobranza de rentas y gestión de desahucio en caso de incumplimiento'
														description='La gestión de cobranza de rentas y la gestión de desahucio en caso de incumplimiento son procesos fundamentales para garantizar el correcto pago'
														onBooking={() => setProtectionType('Gestión de cobranza de rentas')}
													/>
													<Item1
														image={AdviceOnTaxesAndFeesRelatedToLeasing}
														imageActive={AdviceOnTaxesAndFeesRelatedToLeasing}
														title='Asesoría en materia de impuestos relacionados con el arrendamiento'
														description='Es importante contar con una buena asesoría en materia de impuestos y tasas para el arrendamiento, ya que nos permitirá cumplir adecuadamente con nuestras obligaciones fiscales'
														onBooking={() => setProtectionType('Asesoría en materia de impuestos')}
													/>
													<Item1
														image={TrainingAndQualification}
														imageActive={TrainingAndQualification}
														title='Formación y capacitación'
														description='La formación y capacitación son una parte esencial para el desarrollo de los empleados y acesores ya que ayudan a optimizar el proceso de arrendamiento'
														onBooking={() => setProtectionType('Formación y capacitación')}
													/>
												</>
											}
										</div>
									</div>

								</div>
							</div>
							{!(small === false) &&
								<div className='text-center my-3'>
									<Link to={AppRoutes.SERVICES}><b>Ver más ...</b></Link>
								</div>
							}
						</div>
					</div>
				</div>
			</div>


			{/* Modal */}
			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">

					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">Solicitar Cita</h1>
							<button type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => setProtectionType('')}
								ref={buttonCloseModalRef}
							></button>
						</div>
						<div className="modal-body mb-3">

							<h5 className="does_taital" style={{ paddingTop: '1rem' }}>Protección: {protectionType}</h5>
							<div className="contact_section_2">
								<div className="row">
									<div className="col-md-12">
										<div className="mail_section map_form_container">

											{alert === Alerts.Error &&
												<div className="alert alert-error alert-dismissible fade show" role="alert">
													No se pudo enviar la solicitud de la Cita
													<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setAlert(Alerts.Empty)}></button>
												</div>
											}

											<form ref={form} onSubmit={sendEmail}>
												<input type="hidden" name="service" value={protectionType} />
												<input type="text" className="mail_text" placeholder="Nombre" name="user_name" required />
												<input type="tel" className="mail_text" placeholder="Número de Teléfono" name="user_phone" required />
												<input type="email" className="mail_text" placeholder="Correo Electrónico" name="user_email" required />
												<textarea className="massage-bt" placeholder="Breve explicación de su solicitud" rows="5" id="message" name="message" required ></textarea>
												<button type="submit" className="send_bt active btn btn-primary" disabled={!captcha}>Enviar Ahora</button>
											</form>

											<div className="my-3">
												<ReCAPTCHA
													sitekey={enviroment[process.env.NODE_ENV].RECAPTCHA_SITE_KEY}
													onChange={onChange}
												/>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						{/* 
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setProtectionType(null)}>Close</button>
								<button type="button" className="btn btn-primary" onClick={() => form.current.submit()}>Solicitar</button>
							</div> 
						*/}
					</div>

				</div>
			</div>



		</div>
	)
}